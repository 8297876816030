.plan{
    background: $mid-green;
    a{
        font-weight: bold;
        color: $dark-green;
    }
}

.pledge{
    padding: 40px 10vw 40px 10vw;
    position: relative;
    @media screen and (min-width: 850px){
        padding: 80px 20vw 80px 20vw;
    }
}

.pledge__headline{
    text-align: center;
    margin-bottom: 30px;
    @media screen and (min-width: 850px){
        font-size: 2em;
        margin-bottom: 50px;
    }
    @media screen and (min-width: 950px){
        font-size: 2.5em;
    }
}

.pledge__companies{
    width: 100%;
    mix-blend-mode: multiply;
    filter: grayscale(1);
    opacity: 0.8;
    @media screen and (min-width: 850px){
        // margin-bottom: 80px;
    }

}
.pledge__row_1, .pledge__row_2, .pledge__row_3 {
    margin: 0 auto;
    margin-top: -10px;
    a {
        text-decoration: none;
        &:hover {
            .pledge__companies{
                filter: grayscale(0);
                opacity: 1;
            }
        }
    }
}
.pledge__row_1 {
    max-width: 225px;
}
.pledge__row_2 {
    max-width: 700px;
    margin-top: -25px;
    .pledge__companies {
        width: 24%;
    }
}
.pledge__row_3 {
    max-width: 750px;
    .pledge__companies {
        width: 24%;
    }
}

.plan__intro{
    max-width: $narrow-width;
    margin: 0 auto;
    line-height: 1.4;
    margin-bottom: 30px;
    margin: 0px 10vw 50px 10vw;
    @media screen and (min-width: 850px){
        margin: 0 auto;
        margin-bottom: 80px;
    }
    p{
        font-family: $alt-font;
        max-width: $narrow-width;
        margin: 0 auto 20px auto;
        font-size: 1.1em;
        @media screen and (min-width: 850px){
            font-size: 1.3em;
        }
    }
}

.plan__section{
    padding: 50px 10vw 40px 10vw;
    background: $mid-green;
    position: relative;
    background-size: cover;
    background-position: 0% 99%;
    margin-top: -1px;

    &.plan__section--individuals{
        background-color: $pale-green;
    }

    &.plan__section--company{
        background-color: $brand-green;
        p, li, h3 {
            color: $white;
        }
        .commitment__point:before{
            background-image: url("../img/tick-white.svg") !important;
        }
    }
    &.plan__section--clients{
        background-color: $dark-green;
        p, li, h3 {
            color: $white;
        }
        .commitment__point:before{
            background-image: url("../img/tick-white.svg") !important;
        }
    }
    @media screen and (min-width: 850px){
        padding: 80px 20vw 80px 20vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("../img/bg-individual.svg");
        &.plan__section--company{
            background-image: url("../img/bg-company.svg");
        }
        &.plan__section--clients{
            background-image: url("../img/bg-clients.svg");
        }
        &.plan__section--industry{
            background-image: url("../img/bg-industry.svg");
        }

    }
}

.plan__section-title{
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    color: $grey;
}

.commitment{
    max-width: $narrow-width;
    margin: 0 auto;
}

.commitment__points{
    list-style: none;
    margin-bottom: 30px;
    @media screen and (min-width: 850px){
        margin-bottom: 45px;
    }
}

.commitment__name{
    margin-bottom: 10px;
}

.commitment__point{
    font-family: $alt-font;
    color: $grey;
    margin-bottom: 15px;
    position: relative;
    font-size: 1.1em;
    @media screen and (min-width: 850px){
        font-size: 1.2em;
        margin-bottom: 15px;
    }
    &:before{
        content: "";
        display: inline-block;
        height: 12px;
        width: 12px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../img/tick.svg");
        @media screen and (min-width: 850px){
            width: 15px;
            height: 15px;
            margin-right: 12px;
        }
    }
}
