.site-footer{
    padding: 25px;
    text-align: center;
    background: $mid-green;
    @media screen and (min-width: 850px){
        padding: 45px 25px;
    }
    p{
        color: $black;
        opacity: 0.5;
        font-family: $alt-font;
    }
}

.site-footer__link{
    font-family: $alt-font;
    &:hover{
        text-decoration: none
    }
}