.hero{
    padding: 90px 10vw 50px 10vw;
    position: relative;
    background: $mid-green;
    &:after{
        content: ""
    }
    @media screen and (min-width: 850px){
        min-height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 40px 20vw 40px 20vw;
    }
    @media screen and (min-width: 1400px){
        padding: 40px 25vw 40px 25vw;
    }
}

.hero__triangle{
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 50%;
    @media screen and (min-width: 850px){
        height: 85%;
    }
    @media screen and (min-width: 1400px){
        height: 95%;
    }
}

.hero__inner{
    margin: 0 auto;
    background: $white;
    padding: 25px;
    box-shadow: -10px 10px 0px $dark-green;
    position: relative;
    @media screen and (min-width: 850px){
        padding: 40px;
        box-shadow: -20px 20px 0px $dark-green;
    }
    @media screen and (min-width: 960px){
        padding: 55px;
    }
}

.slider{
    margin-bottom: 30px;
    @media screen and (min-width: 850px){
        font-size: 2em;
    }
    @media screen and (min-width: 950px){
        font-size: 2.5em;
    }
    @media screen and (min-width: 1400px){
        font-size: 3em;
    }
}

@keyframes fadeIn {
    from{ 
        opacity: 0;
        transform: translateX(-10px);
    }
    to{ 
        opacity: 1;
        transform: translateX(0px);
    }
}

.slider__message{
    display: block;
    color: $brand-green;
    animation: fadeIn 0.5s ease-out;
    // min-height: 160px;
    // @media screen and (min-width: 550px){
    //     min-height: 40px;
    // }
    // @media screen and (min-width: 850px){
    //     min-height: 180px;
    // }
}

.slider__source{
    font-size: 14px;
    color: $brand-green;
    font-family: $alt-font;
    font-weight: 100;
    position: absolute;
    right: 15px;
    bottom: 15px;
    @media screen and (min-width: 850px){
        font-size: 16px;
    }
    &:after{
        content: "";
        display: inline-block;
        height: 10px;
        width: 15px;
        background-image: url("../img/external-link.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 3px;
    }
}

.slider__tagline{
    font-weight: bold;
    font-size: 1.4em;
    margin-bottom: 15px;
    @media screen and (min-width: 850px){
        font-size: 2em;
    }
    @media screen and (min-width: 950px){
        font-size: 2.5em;
    }
    @media screen and (min-width: 1400px){
        font-size: 3em;
    }
}