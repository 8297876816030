// layout
$widest-width: 1100px;
$narrow-width: 570px;
// colours
$white: #fafafa;
$pale-green: #F0F4F4;
$mid-green: #DBE8E8;
$brand-green: #239794;
$dark-green: #006066;
$yellow: #EEE8A9;
$black: #1c1c1c;

$grey: #494949;

// typography
$main-font: Aleo, serif;
$alt-font: Muli, sans-serif;

*{
    font-family: $main-font;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #1c1c1c;
}

body{
    background: $pale-green;
}

a:hover{
    text-decoration: none;
}

*:focus{
    background: $yellow;
    outline: none;
    box-shadow: 0px 0px 0px 3px $yellow
}

.crosshead{
    color: $dark-green;
    margin-bottom: 20px;
    font-size: 1.5em;
    @media screen and (min-width: 850px){
        font-size: 2.5em;
    }
    @media screen and (min-width: 950px){
        font-size: 3em;
    }
}

.hubspot-link__container {
    display: none;
}

@import "header.scss";
@import "hero.scss";
@import "statement.scss";
@import "plan.scss";
@import "contact.scss";
@import "footer.scss";
